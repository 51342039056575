import React, { useContext, useState } from "react"
import { message, Button, Row, Col,  Drawer, Form, Input, Select, Divider, Alert , Space, Typography} from "antd"
import Icon from '@ant-design/icons';
import { APICreateUser } from '../scripts/users'
import { GlobalStateContext } from "../context/GlobalContextProvider"
import user_icon_svg from '../assets/svgs/user_icon.svg'

const { Option } = Select;
const { Title } = Typography;

const UserIcon = props => <Icon className="wtc-icon users-icon" component={user_icon_svg} {...props} />;

const Logo = (props, { defKey, children }) => {
  const state = useContext(GlobalStateContext)
  const [showOperator, setShowOperator] = useState(false)

  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState("")
  const [statusMessage, setStatusMessage] = useState("")
  const [statusVerbose, setStatusVerbose] = useState("")

  const [form] = Form.useForm();

  // ===========================
  // API REQUESTS
  // ===========================
  const createUser = async data => {
    setLoading(true)
    setStatus("")
    setStatusMessage("")
    setStatusVerbose("")

    await APICreateUser(data)
      .then(response => {
        console.log(response)
        setLoading(false)
        message.success("User created!")
        props.onSuccess();
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
        if(error.type === "validation"){
          setStatus("error")
          setStatusMessage("User role not selected")
          setStatusVerbose("Please check the user details again")
        }
        else{
          setStatus("error")
          setStatusMessage("An error occured!")
          setStatusVerbose(error.error)
        }
      })
  }

  const onRoleChange = value => {
    switch (value) {
      case "operator":
        setShowOperator(true)
        return;
      default:
        setShowOperator(false)
        return;
    }
  }
  const renderVendors = () => {
    return state.vendorsData.map((vendor, index) => {
      return <Option key={index} value={vendor}>{ vendor}</Option>
    })
  }

  return (
    <Drawer
      width={600}
      onClose={props.onClose}
      visible={props.visible}
      bodyStyle={{ padding: 80 }}
    >
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Space align="center" size={20}>
            <UserIcon style={{fontSize: "30px"}}/>
            <Title level={2}>Create a new user</Title>
          </Space>
        </Col>
        <Col span={24}>
          <Divider style={{margin: 0}} />
        </Col>
      </Row>
      <Form layout="horizontal" form={form} hideRequiredMark onFinish={event => createUser(event)}>
        <Form.Item
          name="email"
          label="E-mail"
          labelCol={{span: 8}}
          wrapperCol={{span: 16}}
          labelAlign="left"
          rules={[
            {
              type: 'email',
              message: 'Please enter an email',
            },
            {
              required: true,
              message: 'Please enter an email',
            },
          ]}
        >
          <Input
            
            style={{ width: '100%' }}
            placeholder="Please enter an email"
          />
        </Form.Item>
        <Form.Item
          name="phone_number"
          label="Phone Number"
          labelCol={{span: 8}}
          wrapperCol={{span: 16}}
          labelAlign="left"
          rules={[{ required: false, message: 'Please enter a phone number' }]}
        >
          <Input addonBefore="+61" style={{ width: '100%' }} pattern="[0-9]+" placeholder="Please enter a phone number"/>
        </Form.Item>
        <Form.Item
          name="role"
          label="Role"
          labelCol={{span: 8}}
          wrapperCol={{span: 16}}
          labelAlign="left"
          hasFeedback
          rules={[{ required: true, message: 'Please select a role' }]}
        >
          <Select onChange={onRoleChange} placeholder="Please select a role">
            <Option value="operator">Operator</Option>
            {state.role_level > 0 && <Option value="admin">Admin</Option>}
            {state.role_level > 1 && <Option value="superadmin">Superadmin</Option>}
          </Select>
        </Form.Item>
        { showOperator && 
        <Form.Item
          name="profile"
          label="Operator"
          labelCol={{span: 8}}
          wrapperCol={{span: 16}}
          labelAlign="left"
          hasFeedback
          rules={[{ required: true, message: 'Please select a vendor' }]}
        >
          <Select loading={!state.vendorsLoaded} placeholder="Please select a vendor">
            {state.vendorsLoaded && renderVendors()}
          </Select>
        </Form.Item>
        }
        <Form.Item
          name="given_name"
          label="Given name"
          labelCol={{span: 8}}
          wrapperCol={{span: 16}}
          labelAlign="left"
          rules={[{ required: false, message: 'Please enter a given name' }]}
        >
          <Input placeholder="Please enter a given name" />
        </Form.Item>
        <Form.Item
          name="family_name"
          label="Family name"
          labelCol={{span: 8}}
          wrapperCol={{span: 16}}
          labelAlign="left"
          rules={[{ required: false, message: 'Please enter a family name' }]}
        >
          <Input placeholder="Please enter a family name" />
        </Form.Item>
        <Form.Item>
          <Row>
            <Col offset={8} span={8}>
              <Button loading={loading} block type="primary" htmlType="submit">
                Add User
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
      { status !== "" && <Alert
        // closable
        message={statusMessage}
        description={statusVerbose}
        type={status}
      />}
    </Drawer>
   )
}
export default Logo

