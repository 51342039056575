import React, { useContext, useEffect, useState } from "react";
import { message, Button, Layout, Row, Col, Table, Space, Popover, Tabs, Typography } from "antd"
import { GlobalStateContext, GlobalDispatchContext } from "../context/GlobalContextProvider"
import CreateUserDrawer from "../components/user_CreateDrawer";
import EditUserDrawer from "../components/user_EditDrawer";
import { APIDeleteUser, APIResendCode } from "../scripts/users"
import LoadingPage from "../components/loadingPage"
import { navigate } from "gatsby"

const { Header, Content } = Layout;
const { Title } = Typography;
const { TabPane } = Tabs;
const Users = (props) => {

  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const [createUserDrawerVisible, setCreateUserDrawerVisible] = useState(false);
  const [editUserDrawerVisible, setEditUserDrawerVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState({})

  const [activeUsers, setActiveUsers] = useState([])
  const [pendingUsers, setPendingUsers] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)

   // Delete user
  const deleteUser = async (data, index) => {
    setDeleteLoading(true)
    await APIDeleteUser(data)
      .then(response => {
        console.log(response)
        setDeleteLoading(false)
        message.success("User deleted")
        refreshList();
      })
      .catch(error => {
        console.error(error)
        setDeleteLoading(false)
        if(error.type === "validation"){
          message.error("Error: No User deleted")
        }
        else{
          message.error("An error occured!")
        }
      })
  }

  const resendCode = async (username, email) => {
    setResendLoading(username)
    await APIResendCode(username, email)
      .then(response => {
        console.log(response)
        setResendLoading(false)
        message.success("Code sent!")
      })
      .catch(error => {
        console.error(error)
        setResendLoading(false)
        if(error.type === "validation"){
          message.error("Error: Bad username");
          setResendLoading(false)
        }
        else{
          message.error("An error occured!")
        }
      })
  }

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      width: 400
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      width: 200
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: 120
    },
    {
      title: 'Operator',
      dataIndex: 'profile',
      width: 300
    },
    {
      title: 'Action',
      render: (text, record, index) => {
        let operator = record.role === "operator";
        let disabled = record.role === "superadmin" && state.role_level !== 2
        let imp_name = record.email;
        console.log(record)
        if(!record.given_name)
          record.given_name = "";
        // else
        //   imp_name = record.given_name;
        if(!record.family_name)
          record.family_name = "";
        // else
        //   imp_name = 
        console.log(imp_name)
        let actions = (
        <Space>
          { record.status === "CONFIRMED" ? operator ?  <Button type="primary" onClick={() => impersonateUser(record.profile, imp_name)}>Impersonate</Button> : undefined : <Button type="primary" loading={resendLoading === record.username} onClick={() => resendCode(record.username, record.email)}>Resend</Button> }
          <Button disabled={disabled} onClick={() => showEditUserDrawer(record)}>Edit</Button>
          <Popover trigger={"click"} content={<Space size={10}>Are you sure?<Button type="primary" loading={deleteLoading} danger onClick={() => deleteUser(record)}>YES</Button></Space>}>
            <Button disabled={disabled} danger >Delete</Button>
          </Popover>
        </Space>)
        
        return actions
      }
    }
  ];

  useEffect(() => {
    if(state.auth_state)
      dispatch({ type:'ROUTE', route: "/users"})
  }, [ state.auth_state, dispatch ])


  // Refresh user list
  const refreshList = () => {
    dispatch({ type:'REFRESH', toRefresh: 'refreshUsers'});
  }

  // Show various drawers
  const showCreateUserDrawer = () => setCreateUserDrawerVisible(true)
  const closeCreateUserDrawer = () => setCreateUserDrawerVisible(false)
  const showEditUserDrawer = record => { setSelectedUser(record); setEditUserDrawerVisible(true)}
  const closeEditUserDrawer = () => setEditUserDrawerVisible(false)
  
 

  // Set the vendor to impersonate and redirect
  const impersonateUser = (profile, imp_username) => {
    dispatch({ type:'IMPERSONATE', profile, imp_username });
    dispatch({ type:'REFRESH', toRefresh: 'refreshBookings'});
    dispatch({ type:'REFRESH', toRefresh: 'refreshExperiences'});
    dispatch({ type:'REFRESH', toRefresh: 'refreshBlackouts'});
    navigate('/bookings')
  }

  

  // If user isn't auth'd take to booking screen.
  const authControl = () => {
    if(state.role_level < 1){
      navigate('/bookings')
    }
  }
  useEffect(authControl, [state.auth_state]);

  // Process user state into pending and active
  const processUsers = () => {
    if(state.usersLoaded){
      let active_users = [];
      let pending_users = [];
      for(let user of state.usersData){
        if(user.status === "CONFIRMED")
          active_users.push(user)
        else
          pending_users.push(user)
      }
      setActiveUsers(active_users)
      setPendingUsers(pending_users)
    }
  }
  useEffect(processUsers, [state.usersLoaded]);

  // Render function
  return(
    <div id="users-page" className="page">
      <Header id="header" className="users-view">
        <Row className="main-row">
          <Col className="title" span={6}>
            <Title>Users</Title>
          </Col>
          <Col flex="auto">
            <Row gutter={12} justify="end">
              <Col className="create-booking" span={6}>
                <Button block type="primary" onClick={showCreateUserDrawer}>Add User</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="lower-row" justify="start" gutter={12}>
          <Col className="user-tabs" span={24}>
            <Tabs size="large" onChange={key => setActiveTab(key)} style={{height: "100%"}}>
              <TabPane tab="Active" key="1">
              </TabPane>
              <TabPane tab="Pending" key="2">
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Header>
      <Content>
        <LoadingPage className={state.usersLoaded ? "loaded" : "loading" } />
        <Tabs className={"content-container"} activeKey={activeTab} renderTabBar={(props) => <div></div>}>
          <TabPane tab="" key="1">
            <Table className="user-table" loading={state.loading ? !state.usersLoaded || deleteLoading : false} columns={columns} dataSource={activeUsers} bordered/>
          </TabPane>
          <TabPane tab="" key="2">
            <Table className="user-table" loading={state.loading ? !state.usersLoaded || deleteLoading : false} columns={columns} dataSource={pendingUsers} bordered />
          </TabPane>
        </Tabs>
      </Content>
      <CreateUserDrawer visible={createUserDrawerVisible} onSuccess={() => { setCreateUserDrawerVisible(false); refreshList()}} onClose={closeCreateUserDrawer}/>
      <EditUserDrawer visible={editUserDrawerVisible} user={selectedUser} onSuccess={() => { setEditUserDrawerVisible(false); refreshList()}} onClose={closeEditUserDrawer}/>
     </div>
  )
}

export default Users;
