import React from "react"
import { Row, Col,  Drawer, Divider, Space, Typography } from "antd"
import Icon from '@ant-design/icons';
import EditUserForm from "./user_EditForm"
import user_icon_svg from '../assets/svgs/user_icon.svg'

const { Title } = Typography;
const UserIcon = props => <Icon className="wtc-icon users-icon" component={user_icon_svg} {...props} />;


const EditUserDrawer = (props) => {
  console.log(props.user)
  return (
    <Drawer
      forceRender
      width={600}
      onClose={props.onClose}
      visible={props.visible}
      bodyStyle={{ padding: 80 }}
    >
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Space align="center" size={20}>
            <UserIcon style={{fontSize: "30px"}}/>
            <Title level={2}>Edit user</Title>
          </Space>
        </Col>
        <Col span={24}>
          <Divider style={{margin: 0}} />
        </Col>
      </Row>
      <EditUserForm onSuccess={props.onSuccess} user={props.user}/>
    </Drawer>
   )
}
export default EditUserDrawer

